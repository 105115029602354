.legacy-style {
    .status__prepend {
        padding-top: 0.25em;
        padding-left: 2.15em;
        padding-bottom: 0;
    }
    .status__line {
        display: none;
    }
    .status:not(.status--in-thread) {
        padding: 0.5em 0.5em 0.5em 4.75em;
        position: relative;
    }
    .status .status__info, .status__content {
        padding-bottom: 0.25em;
        margin-left: 0;
    }
    .status--in-thread{
        padding-top: 0.5em;
    }
    .status--in-thread .status__info {
        padding-left: 3.25em;
        padding-bottom: 0;
    }
    .status--in-thread .status__content {
        padding-top: 0.25em;
        padding-left: 3.25em;
        margin-right: 4em;
    }
    .status--in-thread .status__content {
      -webkit-margin-start: 0px;
      margin-inline-start: 0px;
      width: calc(100% - 3.5em);
    }
    .status__wrapper-direct .status--in-thread .status__avatar {
        padding-top: 2em;
    }
    .status--in-thread .status__action-bar {
        margin-left: 3.5em;
    }
    .status-reply {
        padding-top: 0.25em;
    }
    .status .status__info {
        margin-bottom: 0;
    }
    .status .status__relative-time {
        height: auto;
        line-height: normal;
    }
    .status .status__avatar {
        width: 3em;
        height: 3em;
        position: absolute;
        left: 0.5em;
        top: 0.5em;
    }
    .status .display-name:not(.detailed-status__display-name) bdi {
        padding-right: 0.25em;
    }
    .status .display-name:not(.detailed-status__display-name) {
        display: flex;
    }
    .detailed-status {
        padding-top: 0.25em;
        padding-left: 0.75em;
        padding-bottom: 0.5em;
    }
    .detailed-status .status__prepend {
        margin-left: 1.75em;
        margin-bottom: 0.5em;
    }
    .detailed-status__display-name {
        margin-bottom: 0.5em;
    }
    .detailed-status .status-card {
        padding: 0.25em;
    }
    .account__wrapper .display-name, .detailed-status .display-name {
        padding-left: 0;
    }
    .status__action-bar {
        margin-top: 0.25em;
        justify-content: flex-start;
        margin-left: 0;
    }
    .status .media-gallery, .detailed-status .status-card, .detailed-status__meta {
        margin-top: 0.5em;
    }
    .status .attachment-list {
        margin-top: 0.25em;
    }
    .notification-ungrouped {
        padding: 0;

        .status__content {
            width: 100%;
        }
    }
    .notification-ungrouped__header {
        margin-top: 0.5em;
        margin-bottom: 0;
    }
    .notification-group {
      padding: 0.5em;
    }
    .muted {
        padding-top: 0.5em;
    }
    .account__header__bar {
        padding: 0 12px;
    }
    .account__header__tabs__name {
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .account__header__bio .account__header__fields {
        margin: 12px 0 0;
    }
    .account__header__bio .account__header__fields dl {
        padding: 8px 12px;
    }
    .account__header__extra__links {
        margin: 0 -8px;
        padding-top: 12px;
    }
}
