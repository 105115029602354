.compact-padding {
  .status__prepend {
    padding-top: 6px;
    padding-bottom: 0;
  }

  .status {
    padding: 12px;
    padding-bottom: 6px;
  }

  .status-reply, .muted {
    padding-top: 6px;
  }

  .status__info {
    padding-bottom: 6px;
  }

  .status__content {
    padding-top: 0;
  }

  .status__action-bar {
    margin-top: 6px;
    justify-content: flex-start;
  }

  .status .media-gallery {
    margin-top: 6px;
  }

  .status .attachment-list {
    margin-top: 2px;
  }

  .notification__message {
    padding: 12px 12px 0;
  }
}
